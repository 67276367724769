<template>
    <modal-lateral ref="modalAnadirProducto" titulo="Buscar producto para añadir">
        <div class="row mx-0 j-center py-3">
            <div class="col-10">
                <el-input v-model="buscar_producto" size="small" suffix-icon="icon-search" placeholder="Buscar producto" class="w-100 br-20" @input="buscarProducto" />
            </div>
        </div>
        <div v-if="buscar_producto.length < 3" class="custom-scroll overflow-auto" style="height:calc(100vh - 123px)">
            <div class="row mx-0 j-center mt-5">
                <img :src="funImagenGeneral(45)" />
                <div class="col-10 mt-3 j-center">
                    <span class="text-black w-100 f-17 row mx-0 j-center mt-3">¿Qué producto estás buscando?</span>
                </div>
            </div>
        </div>
        <div v-if="buscar_producto.length > 3 && productosBusqueda.length == 0" class="custom-scroll overflow-auto" style="height:calc(100vh - 123px)">
            <div class="row mx-0 j-center mt-5">
                <img :src="funImagenGeneral(52)" width="250" style="max-height:100%;" />
                <div class="col-10 mt-3 j-center">
                    <span class="text-black w-100 f-17 row mx-0 j-center mt-3">No se encontraron resultados para tu busqueda</span>
                </div>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 123px)">
            <div v-for="data in productosBusqueda" :key="data.id" class="row mx-0 align-items-center j-center">
                <div class="col-10 pl-0">
                    <card-agregar-producto :producto="data" />
                </div>
                <i v-if="data.productoAgregado" class="f-28 icon-check-circle text-green" content="Añadido" />
                <i v-else class="minus border-3 bottom icon-plus bg-general text-white " content="Añadir" @click="agregarProductoAlPedido(data.id_producto, data.compra_minima )" />
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import Recurrentes from '~/services/pedidos/pedidosRecurrentes'
export default {
    data(){
        return {
            buscar_producto: '',
            id_recurrente: null,
            productosBusqueda: []
        }
    },
    methods: {
        toggle(id_recurrente){
            this.buscar_producto = '';
            this.id_recurrente = id_recurrente
            this.$refs.modalAnadirProducto.toggle();
        },
        async buscarProducto(){
            try {
                if(this.buscar_producto.length >= 3){
                    let params = {
                        buscar : this.buscar_producto,
                        idRecurrente: this.id_recurrente
                    }
                    const { data } = await Recurrentes.getProductosBusqueda(params);
                    this.productosBusqueda = data.productosBusqueda; 
                }else{
                    this.productosBusqueda = []
                }
            } catch (e){
                return this.errorCatch(e)
            }
        },
        async agregarProductoAlPedido(id_producto, compra_minima){
            try {
                const payload = {
                    idRecurrente : this.id_recurrente,
                    idProducto : id_producto,
                    cantidad : compra_minima
                }
                const { data } = await Recurrentes.anadirProductoAlPedido(payload);
                this.notificacion('Exito', data.mensaje, 'success');
                this.$emit('pedidoAgregado');
                this.$refs.modalAnadirProducto.toggle();

            } catch (error){
                return this.errorCatch(error)
            }
        }
    }
}
</script>

<style scoped>
        .minus{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: var(--color-general);
            border: 1px solid var(--color-general);
        }
</style>
